
export const resourcesBanner = {
	title: "Resources",
	subTitle: "",
	paragraph: [
		`Rhinojohn Prime Metal International Limited organizes and facilitates the marketing and trade of mining products to several buyers around the world thanks to its network of stakeholders who operate in the mining sector in countries with large mineral reserves.`,
	],
	image: {
		src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1653645711/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/desk_i7we97.jpg",
		alt: "Rhino John Prime Metals receptionist waiting for your phone call"
	},
	footer: {
		src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649662392/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/register_gy9vgp.jpg",
		alt: "Client filling a Rhino John Prime Metals Form"
	}
}

export const blogs = [

	{
		title: "Blog Article 1",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649689200/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/warehouse-transformed_htf88q.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "12/09/2021"
	},

	
	{
		title: "Blog Article 2",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649662409/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/tradingbuysell_bpoc8x.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "02/02/2022"
	},


	
	{
		title: "Blog Article 3",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649662381/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/miningtools_u6vdmu.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "05/03/2022"
	},



	
	{
		title: "Blog Article 4",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649688672/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/transitcargo-transformed_xkxslp.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649688672/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/transitcargo-transformed_xkxslp.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "17/05/2022"
	},


]


export const news = [

	{
		title: "News Article 1",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649689200/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/warehouse-transformed_htf88q.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "12/09/2021"
	},

	
	{
		title: "News Article 2",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649662409/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/tradingbuysell_bpoc8x.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "02/02/2022"
	},


	
	{
		title: "News Article 3",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649662381/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/miningtools_u6vdmu.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "05/03/2022"
	},



	
	{
		title: "News Article 4",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649688672/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/transitcargo-transformed_xkxslp.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649688672/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/transitcargo-transformed_xkxslp.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "17/05/2022"
	},


]

export const faqs = [
	{
		question: "Lorem ipsum dolor Question 1",
		answer: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, 
			arcu enim sagittis risus, quis imperdiet urna metus vitae quam. Phasellus nec condimentum orci.
			 Aenean mollis massa vitae rutrum maximus. Aliquam sodales consectetur ex nec commodo. 
			 Nam eu eros id arcu pharetra auctor. Ut facilisis enim nisi, ut ultricies augue ullamcorper a. 
			 In lacinia nibh non volutpat hendrerit. Sed sit amet ornare risus. Pellentesque facilisis turpis dui, 
			 vel accumsan ante varius ac. Aenean dictum dolor sed ligula sollicitudin, a consequat sapien volutpat. 
		`
	},
	{
		question: "Lorem ipsum dolor Question 2",
		answer: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, 
			arcu enim sagittis risus, quis imperdiet urna metus vitae quam. Phasellus nec condimentum orci.
			 Aenean mollis massa vitae rutrum maximus. Aliquam sodales consectetur ex nec commodo. 
			 Nam eu eros id arcu pharetra auctor. Ut facilisis enim nisi, ut ultricies augue ullamcorper a. 
			 In lacinia nibh non volutpat hendrerit. Sed sit amet ornare risus. Pellentesque facilisis turpis dui, 
			 vel accumsan ante varius ac. Aenean dictum dolor sed ligula sollicitudin, a consequat sapien volutpat. 
		`
	},
	{
		question: "Lorem ipsum dolor Question 3",
		answer: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, 
			arcu enim sagittis risus, quis imperdiet urna metus vitae quam. Phasellus nec condimentum orci.
			 Aenean mollis massa vitae rutrum maximus. Aliquam sodales consectetur ex nec commodo. 
			 Nam eu eros id arcu pharetra auctor. Ut facilisis enim nisi, ut ultricies augue ullamcorper a. 
			 In lacinia nibh non volutpat hendrerit. Sed sit amet ornare risus. Pellentesque facilisis turpis dui, 
			 vel accumsan ante varius ac. Aenean dictum dolor sed ligula sollicitudin, a consequat sapien volutpat. 
		`
	},
	{
		question: "Lorem ipsum dolor Question 4",
		answer: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, 
			arcu enim sagittis risus, quis imperdiet urna metus vitae quam. Phasellus nec condimentum orci.
			 Aenean mollis massa vitae rutrum maximus. Aliquam sodales consectetur ex nec commodo. 
			 Nam eu eros id arcu pharetra auctor. Ut facilisis enim nisi, ut ultricies augue ullamcorper a. 
			 In lacinia nibh non volutpat hendrerit. Sed sit amet ornare risus. Pellentesque facilisis turpis dui, 
			 vel accumsan ante varius ac. Aenean dictum dolor sed ligula sollicitudin, a consequat sapien volutpat. 
		`
	},
	{
		question: "Lorem ipsum dolor Question 5",
		answer: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, 
			arcu enim sagittis risus, quis imperdiet urna metus vitae quam. Phasellus nec condimentum orci.
			 Aenean mollis massa vitae rutrum maximus. Aliquam sodales consectetur ex nec commodo. 
			 Nam eu eros id arcu pharetra auctor. Ut facilisis enim nisi, ut ultricies augue ullamcorper a. 
			 In lacinia nibh non volutpat hendrerit. Sed sit amet ornare risus. Pellentesque facilisis turpis dui, 
			 vel accumsan ante varius ac. Aenean dictum dolor sed ligula sollicitudin, a consequat sapien volutpat. 
		`
	},
]

export const blogpost = {
	title: "Blog Article 1",
	subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
	coverImage: {
		src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649689200/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/warehouse-transformed_htf88q.jpg",
		alt: "This title + cover image photo"
	},
	thumbnail : {
		src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
		alt: "This title + thumbnail"
	}, 
	content : [

		{
			header: "Header 1",
			subHeader: "Sub header 1",
			paragraph : [
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`,
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`
			],
			list: [
				{
					title: "List title 1",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 2",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 3",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
			], 
		},

		{
			header: "Header 2",
			subHeader: "Sub header 2",
			paragraph : [
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`,
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`
			],
			list: [
				{
					title: "List title 1",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 2",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 3",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
			], 
		},

		{
			header: "Header 3",
			subHeader: "Sub header 3",
			paragraph : [
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`,
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`
			],
			list: [
				{
					title: "List title 1",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 2",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 3",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
			], 
		},

	],
	author: "Writer Nomeclature",
	tags: [
		"tag 1", "tag 2", "tag 3"
	],
	category: "Category 1",
	date: "12/09/2021"
}

export const newspost = {
	title: "News Article 1",
	subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
	coverImage: {
		src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649689200/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/warehouse-transformed_htf88q.jpg",
		alt: "This title + cover image photo"
	},
	thumbnail : {
		src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
		alt: "This title + thumbnail"
	}, 
	content : [

		{
			header: "Header 1",
			subHeader: "Sub header 1",
			paragraph : [
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`,
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`
			],
			list: [
				{
					title: "List title 1",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 2",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 3",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
			], 
		},

		{
			header: "Header 2",
			subHeader: "Sub header 2",
			paragraph : [
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`,
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`
			],
			list: [
				{
					title: "List title 1",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 2",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 3",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
			], 
		},

		{
			header: "Header 3",
			subHeader: "Sub header 3",
			paragraph : [
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`,
					`
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
						arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
						Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
						Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
						Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
					`
			],
			list: [
				{
					title: "List title 1",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 2",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
				{
					title: "List title 3",
					items: [
						"Lorem ipsum list item body 1 of rhinojon prime metals",
						"Lorem ipsum list item body 2 of rhinojon prime metals",
						"Lorem ipsum list item body 3 of rhinojon prime metals",
						"Lorem ipsum list item body 4 of rhinojon prime metals",
					]
				},
			], 
		},

	],
	author: "Writer Nomeclature",
	tags: [
		"tag 1", "tag 2", "tag 3"
	],
	category: "Category 1",
	date: "12/09/2021"
}

export const threeBlogs = [
	{
		title: "Blog Article 1",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649689200/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/warehouse-transformed_htf88q.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "12/09/2021"
	},

	
	{
		title: "Blog Article 2",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649662409/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/tradingbuysell_bpoc8x.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "02/02/2022"
	},


	
	{
		title: "Blog Article 3",
		subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam.",
		coverImage: {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/v1649662381/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/miningtools_u6vdmu.jpg",
			alt: "This title + cover image photo"
		},
		thumbnail : {
			src: "https://res.cloudinary.com/dqweh6zte/image/upload/c_scale,h_194,w_184/v1649662353/Rhino%20John%20Background%20Video/Rhinojon%20Product%20images/buyandsell_wnuch5.jpg",
			alt: "This title + thumbnail"
		}, 
		content : [

			{
				header: "Header 1",
				subHeader: "Sub header 1",
				list: [
					{
						title: "List title 1",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 2",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
					{
						title: "List title 3",
						items: [
							"Lorem ipsum list item body 1 of rhinojon prime metals",
							"Lorem ipsum list item body 2 of rhinojon prime metals",
							"Lorem ipsum list item body 3 of rhinojon prime metals",
							"Lorem ipsum list item body 4 of rhinojon prime metals",
						]
					},
				], 
				paragraph : [
					{
						title: "Paragraph title 1",
						body: [
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
							`
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pulvinar, 
								arcu ut pretium vehicula, arcu enim sagittis risus, quis imperdiet urna metus vitae quam. 
								Phasellus nec condimentum orci. Aenean mollis massa vitae rutrum maximus. 
								Aliquam sodales consectetur ex nec commodo. Nam eu eros id arcu pharetra auctor. 
								Ut facilisis enim nisi, ut ultricies augue ullamcorper a. In lacinia nibh non volutpat hendrerit.
							`,
						]
					}
				]
			}

		],
		author: "Writer Nomeclature",
		tags: [
			"tag 1", "tag 2", "tag 3"
		],
		category: "Category 1",
		date: "05/03/2022"
	},

]