import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { connect } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Alert, AlertTitle, Box, Button, Grow, Stack } from "@mui/material";
import { styled } from "@mui/system";
import LockResetIcon from "@mui/icons-material/LockReset";

import TextField from "../../../components/formsUI/textfield/textfield";
import { resetPassword } from "../../../redux/auth/authactions";

const styledAuthTextField = {
	"& label": {
		color: "white",
	},
	"& label.Mui-focused": {
		color: "grey",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "white",
	},
	"& .MuiOutlinedInput-root": {
		color: "white",
		"& fieldset": {
			borderColor: "grey !important",
		},
		"&:hover fieldset": {
			borderColor: "white",
		},
		"&.Mui-focused fieldset": {
			borderColor: "white",
		},
	},
	color: "white !important",
};

const loginFormContent = [
	{
		type: "password",
		label: "Password",
		name: "password",
		required: true,
	},
];

const INITIAL_FORM_STATE = {
	password: "",
};

const FORM_VALIDATION = Yup.object().shape({
	password: Yup.string()
		.min(8, "Password is too short - should be 8 characters minimum.")
		.required("Please add a password"),
});

const StyledAuthInputs = styled(Box)(({ theme }) => ({
	margin: "30px 0px",
}));

const ResetPasswordForm = ({ resetPassword, errMessage }) => {
	const [alertMessage, setAlertMessage] = useState("");
	const [alertSeverity, setAlertSeverity] = useState("info");

	const navigate = useNavigate();
	const params = useParams();

	const submitResetPassword = async (values, { resetForm }) => {

		try {
			const reset = await resetPassword(values, params.resetToken);
			console.log("RESET", reset)

			if(reset.success){
				setAlertSeverity("success");
				setAlertMessage(reset.message);
			}

			setTimeout(() => {
				navigate("/auth/login");
			}, 2500);

		} catch (error) {
			
			const { data } = error;
			console.log("ERROR", error)

			setAlertSeverity("error");
			setAlertMessage(data.error);
		}
	};

	return (
		<Box>
			<Stack direction="column" spacing={3}>
				{alertMessage && (
					<Grow in={true}>
						<Alert severity={alertSeverity}>
							<AlertTitle>{alertSeverity}</AlertTitle>
							{alertMessage}
						</Alert>
					</Grow>
				)}

				<Formik
					initialValues={{
						...INITIAL_FORM_STATE,
					}}
					validationSchema={FORM_VALIDATION}
					onSubmit={submitResetPassword}
				>
					<Form>
						{loginFormContent.map((el, i) => (
							<StyledAuthInputs key={i}>
								<TextField
									sx={styledAuthTextField}
									type={el.type}
									name={el.name}
									label={el.label}
									placeholder="MyComplexPassword1234"
									required
								/>
							</StyledAuthInputs>
						))}
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							endIcon={<LockResetIcon />}
							sx={{color: "black"}}
						>
							Reset Password
						</Button>
					</Form>
				</Formik>

			</Stack>

		</Box>
	);
};

const mapStateToProps = ({ auth }) => ({
	errMessage: auth.resetError,
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (values, params) => dispatch(resetPassword(values, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
