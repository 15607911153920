import axios from "axios";
import quotationTypes from "./quotationtypes";

export const gettingQuotation = () => ({
	type: quotationTypes.GET_ALL_QUOTATION_START,
})

export const getAllQuotationSuccess = (storage) => ({
	type: quotationTypes.GET_ALL_QUOTATION_SUCCESS,
	payload: storage,
})

export const getAllQuotationFail = (errMessage) => ({
	type: quotationTypes.GET_ALL_QUOTATION_FAIL,
	payload: errMessage,
})



export const postingQuotation = () => ({
	type: quotationTypes.POST_QUOTATION_START,
})

export const postQuotationSuccess = (values) => ({
	type: quotationTypes.POST_QUOTATION_SUCCESS,
	payload: values,
})

export const postQuotationFail = (errMessage) => ({
	type: quotationTypes.POST_QUOTATION_FAIL,
	payload: errMessage,
})



export const startDeleteQuotation = () => ({
	type: quotationTypes.DELETE_QUOTATION_START,
})

export const deleteQuotationSuccess = (values) => ({
	type: quotationTypes.DELETE_QUOTATION_SUCCESS,
	payload: values,
})

export const deleteQuotationFail = (errMessage) => ({
	type: quotationTypes.DELETE_QUOTATION_FAIL,
	payload: errMessage,
})



export const getQuotation = (token) => {
	return async (dispatch) => {
		try {
			const res = await axios.get(
				// "https://rhino-server.onrender.com/api/quotation/all",
				"https://rhino-server.onrender.com/api/quotation/all",
				{
					headers: {
						"Content-Type": "application/json",
						Authorization:`Bearer ${token}`
					},
				}
			)
			gettingQuotation()
			dispatch(getAllQuotationSuccess(res.data.data))
		} catch (error) {
			dispatch(getAllQuotationFail(error?.response?.data?.error))
			throw error
		}
	}
}


export const postQuotation = (values) => {
	return async (dispatch) => {
		try {
			const res = await axios.post(
				// `https://rhino-server.onrender.com/api/quotation/post`,
				`https://rhino-server.onrender.com/api/quotation/post`,
				values
				)
			
			postingQuotation()
			dispatch(postQuotationSuccess(res.data.data))
			return res
		} catch (error) {
			dispatch(postQuotationFail(error.response.data.error))
			throw error
		}
	}
}

//delete quotation
export const deleteQuotation = (id, token) => {
	console.log("DELETE HERE", id, token)
	return async (dispatch) => {
		try {
			const res = await axios.delete(
				`https://rhino-server.onrender.com/api/quotation/delete/${id}`,
				{
					headers : {
						Authorization:`Bearer ${token}`
					}
				}
			)

			dispatch(deleteQuotationSuccess(res?.data?.data))
			console.log("The response is", res)
			return res
		} catch (error) {
			dispatch(deleteQuotationFail(error.response))
			throw error
		}
	}
}