const quotationTypes = {

	GET_ALL_QUOTATION_START: "GET_ALL_QUOTATION_START",
	GET_ALL_QUOTATION_SUCCESS: "GET_ALL_QUOTATION_SUCCESS",
	GET_ALL_QUOTATION_FAIL: "GET_ALL_QUOTATION_FAIL",

	GET_ONE_QUOTATION_START: "GET_ONE_QUOTATION_START",
	GET_ONE_QUOTATION_SUCCESS: "GET_ONE_QUOTATION_SUCCESS",
	GET_ONE_QUOTATION_FAIL: "GET_ONE_QUOTATION_FAIL",

	POST_QUOTATION_START: "POST_QUOTATION_START",
	POST_QUOTATION_SUCCESS: "POST_QUOTATION_SUCCESS",
	POST_QUOTATION_FAIL: "POST_QUOTATION_FAIL",

	DELETE_QUOTATION_START: "DELETE_QUOTATION_START",
	DELETE_QUOTATION_SUCCESS: "DELETE_QUOTATION_SUCCESS",
	DELETE_QUOTATION_FAIL: "DELETE_QUOTATION_FAIL",

};

export default quotationTypes;