import RegisterForm from "./form";

import { Box, Container, Paper } from "@mui/material";
import { styled } from "@mui/system";

const StyledWrapper = styled(Box)(({ theme }) => ({
	height: "100vh",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

const StyledPaper = styled(Paper)(({theme}) => ({
	padding: "10px",
}))

const Register = () => {
	return (
		<StyledWrapper>
			<Container maxWidth="lg">
				<StyledPaper>
					<RegisterForm />
				</StyledPaper>
			</Container>
		</StyledWrapper>
	);
};

export default Register;
