import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { Alert, AlertTitle, Box, Button, Grow, Stack } from "@mui/material";
import { styled } from "@mui/system";

import SendIcon from "@mui/icons-material/Send";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextField from "../../../components/formsUI/textfield/textfield";

import { connect } from "react-redux";
import { loginUser } from "../../../redux/auth/authactions";

const styledAuthTextField = {
	"& label": {
		color: "white",
	},
	"& label.Mui-focused": {
		color: "grey",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "white",
	},
	"& .MuiOutlinedInput-root": {
		color: "white",
		"& fieldset": {
			borderColor: "grey !important",
		},
		"&:hover fieldset": {
			borderColor: "white",
		},
		"&.Mui-focused fieldset": {
			borderColor: "white",
		},
	},
	color: "white !important",
};

const loginFormContent = [
	{
		type: "email",
		label: "Email",
		name: "email",
		required: true,
		placeholder: "emai@mail.com",
	},
	{
		type: "password",
		label: "Password",
		name: "password",
		required: true,
		placeholder: "MyComplexPassword",
	},
];

const INITIAL_FORM_STATE = {
	email: "",
	password: "",
};

const FORM_VALIDATION = Yup.object().shape({
	email: Yup.string()
		.email("Please add a valid email")
		.required("Please add an email"),
	password: Yup.string()
		.min(8, "Password is too short - should be 8 chars minimum.")
		.required("Please add a password"),
});

const StyledAuthInputs = styled(Box)(({ theme }) => ({
	margin: "30px 0px",
}));

const LoginForm = ({ loginUser, user }) => {
	const [alertMessage, setAlertMessage] = useState("");
	const [alertSeverity, setAlertSeverity] = useState("info");

	const navRoute = useNavigate();



	const submitLogin = async (values, { resetForm }) => {
		try {
			const login = await loginUser(values);

			if (login.success) {
				resetForm();

				setAlertSeverity("success");
				setAlertMessage(login.message);
			}

			setTimeout(() => {
				navRoute("/dashboard");
			}, 2500);

		} catch (error) {
			const { data } = error;

			setAlertSeverity("error");
			setAlertMessage(data.error);
		}
	};

	return (
		<Box>
			<Stack direction="column" spacing={3}>

				{alertMessage && (
					<Grow in={true}>
						<Alert severity={alertSeverity}>
							<AlertTitle>{alertSeverity}</AlertTitle>
							{alertMessage}
						</Alert>
					</Grow>
				)}
				
				<Formik
					initialValues={{
						...INITIAL_FORM_STATE,
					}}
					validationSchema={FORM_VALIDATION}
					onSubmit={submitLogin}
				>
					<Form>
						{loginFormContent.map((el, i) => (
							<StyledAuthInputs key={i}>
								<TextField
									sx={styledAuthTextField}
									type={el.type}
									name={el.name}
									label={el.label}
									size="medium"
									placeholder={el.placeholder}
									required
								/>
							</StyledAuthInputs>
						))}
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							endIcon={<SendIcon />}
							sx={{ color: "black" }}
						>
							Login
						</Button>
					</Form>
				</Formik>
			</Stack>
		</Box>
	);
};

const mapStateToProps = ({ auth, user }) => ({
	isAuthenticated: auth.isAuthenticated,
	errMessage: auth.loginError,
	user: user.user,
});

const mapDispatchToProps = (dispatch) => ({
	loginUser: (values) => dispatch(loginUser(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
