const blogTypes = {
	GET_ALL_BLOGS_START: "GET_ALL_BLOGS_START",
	GET_ALL_BLOGS_SUCCESS: "GET_ALL_BLOGS_SUCCESS",
	GET_ALL_BLOGS_FAIL: "GET_ALL_BLOGS_FAIL",

	GET_ONE_BLOG_START: "GET_ONE_BLOG_START",
	GET_ONE_BLOG_SUCCESS: "GET_ONE_BLOG_SUCCESS",
	GET_ONE_BLOG_FAIL: "GET_ONE_BLOG_FAIL",

	POST_BLOG_START: "POST_BLOG_START",
	POST_BLOG_SUCCESS: "POST_BLOG_SUCCESS",
	POST_BLOG_FAIL: "POST_BLOG_FAIL",

	EDIT_BLOG_START: "EDIT_BLOG_START",
	EDIT_BLOG_SUCCESS: "EDIT_BLOG_SUCCESS",
	EDIT_BLOG_FAIL: "EDIT_BLOG_FAIL",

	DELETE_BLOG_START: "DELETE_BLOG_START",
	DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
	DELETE_BLOG_FAIL: "DELETE_BLOG_FAIL",

	WRITE_NEW_BLOG: "WRITE_NEW_BLOG",
	WRITE_NEW_BLOG_FAIL: "WRITE_NEW_BLOG_FAIL",

	WRITE_NEW_BLOG_PARAGRAPH: "WRITE_NEW_BLOG_PARAGRAPH",
	WRITE_NEW_BLOG_PARAGRAPH_FAIL: "WRITE_NEW_BLOG_PARAGRAPH_FAIL",

	WRITE_NEW_BLOG_LIST: "WRITE_NEW_BLOG_LIST",
	WRITE_NEW_BLOG_LIST_FAIL: "WRITE_NEW_BLOG_LIST_FAIL",

	WRITE_NEW_BLOG_TAG: "WRITE_NEW_BLOG_TAG",
	WRITE_NEW_BLOG_TAG_FAIL: "WRITE_NEW_BLOG_PARAGRAPH_FAIL"

}

export default blogTypes