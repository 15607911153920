import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
	Card,
	Container,
	Divider,
	Stack,
	Typography,
} from "@mui/material";
import LoginForm from "./form";

import { connect } from "react-redux";
import { useTheme } from "@emotion/react";

const Login = ({ user }) => {
	const navigate = useNavigate();
	const theme = useTheme();

	useEffect(() => {
		if (user) {
			console.log("OUR USER IS", user);
			return navigate("/dashboard");
		}
	}, [user]);

	return (
		<Stack
			direction="column"
			spacing={2}
			alignItems="center"
			justifyContent="center"
			sx={{ height: "100vh" }}
		>
			<Container maxWidth="md">
				<Card
					sx={{
						backgroundColor: "rgba(0, 0, 0, 0.86)",
						padding: 3,
					}}
				>
					<Stack direction="column" spacing={3}>
						<Typography variant="h4" color="primary">
							Login
						</Typography>

						<Divider
							sx={{ backgroundColor: theme.palette.primary.main }}
						/>

						<LoginForm />

						<Divider
							sx={{ backgroundColor: theme.palette.primary.main }}
						/>

						<Stack>
							<Typography color="primary">
								Forgot your Password?{" "}
							</Typography>
							<Typography
								color="info"
								sx={{ cursor: "pointer" }}
								onClick={() => navigate("/auth/forgotpassword")}
							>
								Reset Password
							</Typography>
						</Stack>
					</Stack>
				</Card>
			</Container>
		</Stack>
	);
};

const mapStateToProps = ({ user }) => ({
	user: user.user,
});

export default connect(mapStateToProps)(Login);
