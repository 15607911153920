import React, { useState } from "react";


import { Alert, AlertTitle, Box, Button, Grow, Stack } from "@mui/material";
import { styled } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextField from "../../../components/formsUI/textfield/textfield";

import { connect } from "react-redux";
import { forgotPassword } from "../../../redux/auth/authactions";

const styledAuthTextField = {
	"& label": {
		color: "white",
	},
	"& label.Mui-focused": {
		color: "grey",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "white",
	},
	"& .MuiOutlinedInput-root": {
		color: "white",
		"& fieldset": {
			borderColor: "grey !important",
		},
		"&:hover fieldset": {
			borderColor: "white",
		},
		"&.Mui-focused fieldset": {
			borderColor: "white",
		},
	},
	color: "white !important",
};

const forgotPasswordFormContent = [
	{
		type: "email",
		label: "Email",
		name: "email",
		required: true,
		placeholder: "emai@mail.com",
	},
];

const INITIAL_FORM_STATE = {
	email: "",
};

const FORM_VALIDATION = Yup.object().shape({
	email: Yup.string()
		.email("Please add a valid email")
		.required("Please add an email"),
});

const StyledAuthInputs = styled(Box)(({ theme }) => ({
	margin: "30px 0px",
}));

const ForgotPasswordForm = ({ forgotPassword, errMessage, data }) => {
	const [alertMessage, setAlertMessage] = useState("");
	const [alertSeverity, setAlertSeverity] = useState("info");

	const submitForgotPassword = async (values, { resetForm }) => {
		try {
			const forgotPasswordReq = await forgotPassword(values);

			if(forgotPasswordReq.success){
				setAlertSeverity("success");
				setAlertMessage(forgotPasswordReq.message);
			}

		} catch (error) {
			const { data } = error;

			setAlertSeverity("error");
			setAlertMessage(data.error);
		}
	};

	return (
		<Box>
			<Stack direction="column" spacing={3}>
				{alertMessage && (
					<Grow in={true}>
						<Alert severity={alertSeverity}>
							<AlertTitle>{alertSeverity}</AlertTitle>
							{alertMessage}
						</Alert>
					</Grow>
				)}

				<Formik
					initialValues={{
						...INITIAL_FORM_STATE,
					}}
					validationSchema={FORM_VALIDATION}
					onSubmit={submitForgotPassword}
				>
					<Form>
						{forgotPasswordFormContent.map((el, i) => (
							<StyledAuthInputs key={i}>
								<TextField
									sx={styledAuthTextField}
									type={el.type}
									name={el.name}
									label={el.label}
									size="medium"
									placeholder={el.placeholder}
									required
								/>
							</StyledAuthInputs>
						))}

						<Button
							type="submit"
							variant="contained"
							color="secondary"
							endIcon={<SendIcon />}
							style={{ color: "black" }}
						>
							Reset Password
						</Button>
					</Form>
				</Formik>
			</Stack>
		</Box>
	);
};

const mapStateToProps = ({ auth }) => ({
	isAuthenticated: auth.isAuthenticated,
	errMessage: auth.forgotError,
	data: auth.data,
});

const mapDispatchToProps = (dispatch) => ({
	forgotPassword: (values) => dispatch(forgotPassword(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
