import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	Alert,
	AlertTitle,
	Box,
	Button,
	ButtonGroup,
	Grid,
	Grow,
	Stack,
	Typography,
} from "@mui/material";
import { styled } from "@mui/system";

import CheckBoxField from "../../../components/formsUI/checkbox/checkbox";
import TextField from "../../../components/formsUI/textfield/textfield";

import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";

import { userFormContent } from "./data";

import { connect } from "react-redux";
import { registerUser } from "../../../redux/auth/authactions";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const StyledWrapper = styled(Box)(({ theme }) => ({
	padding: 10,
}));

const INITIAL_FORM_STATE = {
	firstname: "",
	lastname: "",
	email: "",
	telephone: "",
	city: "",
	country: "",
	password: "",
	authorization: "",
};

const FORM_VALIDATION = Yup.object().shape({
	firstname: Yup.string().min(2).required("Please add your firstname"),
	lastname: Yup.string().required("Please add your lastname"),
	email: Yup.string()
		.email("Please add a valid email")
		.required("Please add an email"),
	telephone: Yup.string().required("Please add your telephone number"),
	city: Yup.string().required("Please add your city"),
	country: Yup.string().required("Please add your country"),
	password: Yup.string().min(8).required("Please add your password"),
	authorization: Yup.string().required(
		"Please add your authentication status"
	),
});

const RegisterForm = ({ registerUser, errMessage }) => {

	const [alertMessage, setAlertMessage] = useState("");
	const [alertSeverity, setAlertSeverity] = useState("info");

	const navigate = useNavigate();

	const submitHandler = async (values, { resetForm }) => {

		try {
			const register = await registerUser(values);
			console.log("REGISTER", register)
	
			if(register.success){
				resetForm();

				setAlertSeverity("success")
				setAlertMessage(register.message)
			}

			setTimeout(() => {
				navigate("/dashboard")
			}, 2500);
			
		} catch (error) {
			const { data } = error
			console.log("REG ERROR RES", data)
			setAlertSeverity("error")
			setAlertMessage(data.error)
		}
	};

	return (
		<StyledWrapper container spacing={2}>
			<Stack direction="column" spacing={3}>
				{alertMessage && (
					<Grow in={true}>
						<Alert severity={alertSeverity}>
							<AlertTitle>{alertSeverity}</AlertTitle>
							{alertMessage}
						</Alert>
					</Grow>
				)}
				

				<Formik
					initialValues={{
						...INITIAL_FORM_STATE,
					}}
					validationSchema={FORM_VALIDATION}
					onSubmit={submitHandler}
				>
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									variant="h4"
									color="secondary"
									gutterBottom
								>
									Register
								</Typography>
							</Grid>

							{userFormContent.map((el, i) => (
								<Grid key={i} item sm={el.sm} xs={el.xs}>
									<TextField
										type={el.type}
										name={el.name}
										label={el.label}
										placeholder={el?.placeholder}
									/>
								</Grid>
							))}

							<Grid item>
								<CheckBoxField
									name="authorization"
									label="authorization"
									legend="Authorization"
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							color="secondary"
							sx={{ color: "black", mt:2 }}
							endIcon={<SendIcon />}
							variant="contained"
						>
							Register
						</Button>
					</Form>
				</Formik>
			</Stack>

		</StyledWrapper>
	);
};

const mapStateToProps = ({ auth }) => ({
	errMessage: auth.errMessage,
});

const mapDispatchToProps = (dispatch) => ({
	registerUser: (values) => dispatch(registerUser(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
