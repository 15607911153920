const shipmentTypes = {
	GET_ALL_SHIPMENT_START: 'GET_ALL_SHIPMENT_START',
	GET_ALL_SHIPMENT_SUCCESS: 'GET_ALL_SHIPMENT_SUCCESS',
	GET_ALL_SHIPMENT_FAIL: 'GET_ALL_SHIPMENT_FAIL',

	GET_ONE_SHIPMENT_START: "GET_ONE_SHIPMENT_START",
	GET_ONE_SHIPMENT_SUCCESS: "GET_ONE_SHIPMENT_SUCCESS",
	GET_ONE_SHIPMENT_FAIL: "GET_ONE_SHIPMENT_FAIL",

	GET_SHIPMENT_BY_TRACKNO_START: "GET_SHIPMENT_BY_TRACKNO_START",
	GET_SHIPMENT_BY_TRACKNO_SUCCESS: "GET_SHIPMENT_BY_TRACKNO_SUCCESS",
	GET_SHIPMENT_BY_TRACKNO_FAIL: "GET_SHIPMENT_BY_TRACKNO_FAIL",

	POST_SHIPMENT_START: "POST_SHIPMENT_START",
	POST_SHIPMENT_SUCCESS: "POST_SHIPMENT_SUCCESS",
	POST_SHIPMENT_FAIL: "POST_SHIPMENT_FAIL",

	EDIT_SHIPMENT_START: "EDIT_SHIPMENT_START",
	EDIT_SHIPMENT_SUCCESS: "EDIT_SHIPMENT_SUCCESS",
	EDIT_SHIPMENT_FAIL: "EDIT_SHIPMENT_FAIL",

	DELETE_SHIPMENT_START: "DELETE_SHIPMENT_START",
	DELETE_SHIPMENT_SUCCESS: "DELETE_SHIPMENT_SUCCESS",
	DELETE_SHIPMENT_FAIL: "DELETE_SHIPMENT_FAIL",

	SET_EVENT_START: "SET_EVENT_START",
	SET_EVENT_SUCCESS: "SET_EVENT_SUCCESS",
	SET_EVENT_FAIL: "SET_EVENT_FAIL",

	ADD_SHIPMENT_EVENT_START: "ADD_SHIPMENT_EVENT_START",
	ADD_SHIPMENT_EVENT_SUCCESS: "ADD_SHIPMENT_EVENT_SUCCESS",
	ADD_SHIPMENT_EVENT_FAIL: "ADD_SHIPMENT_EVENT_FAIL",

	EDIT_SHIPMENT_EVENT_START: "EDIT_SHIPMENT_EVENT_START",
	EDIT_SHIPMENT_EVENT_SUCCESS: "EDIT_SHIPMENT_EVENT_SUCCESS",
	EDIT_SHIPMENT_EVENT_FAIL: "EDIT_SHIPMENT_EVENT_FAIL",

	DELETE_SHIPMENT_EVENT_START: "DELETE_SHIPMENT_EVENT_START",
	DELETE_SHIPMENT_EVENT_SUCCESS: "DELETE_SHIPMENT_EVENT_SUCCESS",
	DELETE_SHIPMENT_EVENT_FAIL: "DELETE_SHIPMENT_EVENT_FAIL",
};

export default shipmentTypes;