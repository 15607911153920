const emailTypes = {
	SENDING_EMAIL_START: 'SENDING_EMAIL_START',
	SENDING_EMAIL_SUCCESS: 'SENDING_EMAIL_SUCCESS',
	SENDING_EMAIL_FAIL: 'SENDING_EMAIL_FAIL',

    GET_ALL_EMAILS_START: "GET_ALL_EMAILS_START",
    GET_ALL_EMAILS_SUCCESS: "GET_ALL_EMAILS_SUCCESS",
    GET_ALL_EMAILS_FAIL: "GET_ALL_EMAILS_FAIL"
};

export default emailTypes;