const userTypes = {
	START_FETCH_USER: 'START_FETCH_USER',
	SUCCESS_FETCH_USER: 'SUCCESS_FETCH_USER',
	FAIL_FETCH_USER: 'FAIL_FETCH_USER',

	
	START_FETCH_USERS: 'START_FETCH_USERS',
	SUCCESS_FETCH_USERS: 'SUCCESS_FETCH_USERS',
	FAIL_FETCH_USERS: 'FAIL_FETCH_USERS',

	
	START_FETCH_ADMIN: 'START_FETCH_ADMIN',
	SUCCESS_FETCH_ADMIN: 'SUCCESS_FETCH_ADMIN',
	FAIL_FETCH_ADMIN: 'FAIL_FETCH_ADMIN',

	
	START_EDIT_ADMIN: 'START_EDIT_ADMIN',
	SUCCESS_EDIT_ADMIN: 'SUCCESS_EDIT_ADMIN',
	FAIL_EDIT_ADMIN: 'FAIL_EDIT_ADMIN',


};

export default userTypes;