const authTypes = {
	START_REGISTER_USER: 'START_REGISTER_USER',
	SUCCESS_REGISTER_USER: 'SUCCESS_REGISTER_USER',
	FAIL_REGISTER_USER: 'FAIL_REGISTER_USER',

	START_LOGIN_USER: 'START_LOGIN_USER',
	SUCCESS_LOGIN_USER: 'SUCCESS_LOGIN_USER',
	FAIL_LOGIN_USER: 'FAIL_LOGIN_USER',
	
	START_FORGOT_PASSWORD: "START_FORGOT_PASSWORD",
	SUCCESS_FORGOT_PASSWORD: "SUCCESS_FORGOT_PASSWORD",
	FAIL_FORGOT_PASSWORD: "FAIL_FORGOT_PASSWORD",
	
	START_RESET_PASSWORD: "START_RESET_PASSWORD",
	SUCCESS_RESET_PASSWORD: "SUCCESS_RESET_PASSWORD",
	FAIL_RESET_PASSWORD: "FAIL_RESET_PASSWORD",
	
	LOGOUT_USER: 'LOGOUT_USER',
}

export default authTypes