const storageTypes = {
	GET_ALL_STORAGE_START: 'GET_ALL_STORAGE_START',
	GET_ALL_STORAGE_SUCCESS: 'GET_ALL_STORAGE_SUCCESS',
	GET_ALL_STORAGE_FAIL: 'GET_ALL_STORAGE_FAIL',

	GET_ONE_STORAGE_START: "GET_ONE_STORAGE_START",
	GET_ONE_STORAGE_SUCCESS: "GET_ONE_STORAGE_SUCCESS",
	GET_ONE_STORAGE_FAIL: "GET_ONE_STORAGE_FAIL",
	
	GET_STORAGE_BY_TRACKNO_START: "GET_STORAGE_BY_TRACKNO_START",
	GET_STORAGE_BY_TRACKNO_SUCCESS: "GET_STORAGE_BY_TRACKNO_SUCCESS",
	GET_STORAGE_BY_TRACKNO_FAIL: "GET_STORAGE_BY_TRACKNO_FAIL",

	POST_STORAGE_START: "POST_STORAGE_START",
	POST_STORAGE_SUCCESS: "POST_STORAGE_SUCCESS",
	POST_STORAGE_FAIL: "POST_STORAGE_FAIL",

	EDIT_STORAGE_START: "EDIT_STORAGE_START",
	EDIT_STORAGE_SUCCESS: "EDIT_STORAGE_SUCCESS",
	EDIT_STORAGE_FAIL: "EDIT_STORAGE_FAIL",

	DELETE_STORAGE_START: "DELETE_STORAGE_START",
	DELETE_STORAGE_SUCCESS: "DELETE_STORAGE_SUCCESS",
	DELETE_STORAGE_FAIL: "DELETE_STORAGE_FAIL",
};

export default storageTypes;