import React from "react";

import {
	Card,
	Container,
	Divider,
	Stack,
	Typography,
} from "@mui/material";


import ForgotPasswordForm from "./form.js";

import { useTheme } from "@emotion/react";


const ForgotPassword = () => {
	const theme = useTheme();
	
	return (
		<Stack
			direction="column"
			spacing={2}
			alignItems="center"
			justifyContent="center"
			sx={{ height: "100vh" }}
		>
			<Container maxWidth="md">
				<Card
					sx={{
						backgroundColor: "rgba(0, 0, 0, 0.86)",
						padding: 3,
					}}
				>
					<Stack direction="column" spacing={3}>
						<Typography variant="h4" color="primary">
							Send Reset Password Request
						</Typography>

						<Divider
							sx={{ backgroundColor: theme.palette.primary.main }}
						/>

						<ForgotPasswordForm />
					</Stack>
				</Card>
			</Container>
		</Stack>
	);
};

export default ForgotPassword;
